
import { CurrentView } from '~/store/building/-constants'
import { smoothScrollToTargetId } from '~/helpers/util'
import baseConstants from '~/store/base/-constants'
import tutorialConstants from '~/store/tutorial/-constants'
import availabilityConstants from '~/store/availability/-constants'
import projectConstants from '~/store/project/-constants'

export default {
  name: 'SectionBuildingV2',
  props: {
    data: {
      required: true,
      type: Object || Array,
      default: function () {
        return {
          backgroundLoader: '',
          disclaimer: '',
          contentLoader: [],
          matterportSpace: []
        }
      }
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0
    },
    hash: {
      required: true,
      type: String,
      default: ''
    },
    enableEmbedVersion: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    const regex = /\/project\/.+\/space\/[0-9]+/i
    const regexEmbed = /\/embed\/.+\/space\/[0-9]+/i
    const isSpaceRoute = regex.test(this.$route.path)
    const isEmbedSpaceRoute = regexEmbed.test(this.$route.path)
    return {
      windowHeight: typeof window !== 'undefined' ? `${window.innerHeight}px` : '100vh',
      headerHeight: 0,
      isMobileView: typeof window !== 'undefined' ? window.innerWidth < 1200 : false,
      spaceDetailsOpen: isSpaceRoute || isEmbedSpaceRoute
    }
  },
  computed: {
    isMenuOpen() {
      return this.$store.state.base?.isAvailabilityMenuOpen
    },
    requestedSpaces() {
      return this.$store.state.requestOffer.spaces
    },
    totalSpace() {
      let total = 0
      this.requestedSpaces.forEach((s) => {
        total += s.sqm
      })
      return total
    },
    unitOfMeasure() {
      return this.$store.state.base?.meta?.generalConfig?.unitOfMeasure
    },
    unitOfMeasureString() {
      return this.$store.state.base?.meta?.generalConfig?.unitOfMeasure === 1 ? 'sqft' : 'sqm'
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    projectFeatures() {
      return this.$store.getters?.featuresForProject(this.activeProject)
    },
    disableList() {
      return this.projectFeatures?.disableMyList
    },
    isFloorView() {
      return this.$store.state.building.currentView === CurrentView.FLOOR
    },
    loadingErrorOccurred() {
      return this.$store.state.building.loadingErrorOccurred
    },
    contentLoaderData() {
      return this.data?.contentLoader
    },
    videosLoader() {
      return this.data?.videosLoader || []
    },
    spacesCounter() {
      return this.$store.state.requestOffer.spaces.length
    },
    guidedTourModeEnabled() {
      return this.$store.state.guidedTour.guidedTourModeEnabled
    },
    shouldOnlyDisplay3dSection() {
      return this.guidedTourModeEnabled
    },
    availableTours() {
      return this.$store.state.guidedTour.availableTours || []
    },
    hasGuidedTours() {
      return this.availableTours.length > 0
    },
    generalConfig() {
      return this.$store?.state?.base?.meta?.generalConfig
    },
    selectedSpace() {
      return Number(this.$route.params.space)
    },
    isSpaceRoute() {
      const regex = /\/project\/.+\/space\/[0-9]+/i
      return regex.test(this.$route.path)
    },
    isEmbedSpaceRoute() {
      const regex = /\/embed\/.+\/space\/[0-9]+/i
      return regex.test(this.$route.path)
    },
    disableButtonsActions() {
      const { disableButtonsActions } = this.generalConfig
      const findDisableButtonsActionsByProject = disableButtonsActions?.[this.activeProject]
        ? disableButtonsActions[this.activeProject]
        : null
      if (findDisableButtonsActionsByProject) {
        return {
          exploreSpacePage: findDisableButtonsActionsByProject.exploreSpacePage || false,
          requestOffer: findDisableButtonsActionsByProject.requestOffer || false,
          disableWalkThrough: findDisableButtonsActionsByProject.disableWalkThrough || false
        }
      }
      return {
        exploreSpacePage: false,
        requestOffer: false,
        disableWalkThrough: false
      }
    },
    spaceData() {
      return this.$store.state.project.spaceData
    },
    engine3d() {
      return this.$engine3d || window.engine3d
    }
  },
  watch: {
    selectedSpace(val) {
      if (!val) {
        return
      }
      if (window.innerWidth < 768) {
        this.sidebarHideMobile()
      } else if (!this.spaceDetailsOpen) {
        this.handleExpandSpaceDetails()
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.resizeListener)
    this.headerHeight = document.getElementById('header-project')
      ? document.getElementById('header-project').clientHeight
      : 0
    // this.clearFilters() // TODO: gabi check if needed or add a flag when you have query params to not execute it like this...
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    goToNextSection() {
      const sections = this.$parent.$parent.$parent.sections
      const targetSection = sections[this.orderNo]
      const targetString = targetSection.hashAnchor
      if (sections && sections.length > 1) {
        smoothScrollToTargetId(`#${targetString}`)
      }
    },
    resizeListener(e) {
      this.windowHeight = window.innerHeight
      this.headerHeight = document.getElementById('header-project')
        ? document.getElementById('header-project').clientHeight
        : 0
      this.isMobileView = typeof window !== 'undefined' ? window.innerWidth < 1200 : false
    },
    toggleEmbedSidebar() {
      this.$store.dispatch(
        baseConstants.withNamespace(baseConstants.action.TOGGLE_AVAILABILITY_MENU)
      )
      this.$store.dispatch(
        baseConstants.withNamespace(baseConstants.action.CHANGE_SIDEBAR_STATE),
        false
      )
    },
    toggleCartSidebar() {
      this.$store.dispatch(
        baseConstants.withNamespace(baseConstants.action.CHANGE_SIDEBAR_STATE),
        false
      )
      this.$store.dispatch(
        baseConstants.withNamespace(baseConstants.action.CHANGE_MOBILE_AVAILABILITY_MENU),
        false
      )
    },
    infoToggle() {},
    handleCloseGuidedTourPresentation() {
      this.$refs['guided-tour-presentation-drawer']?.closeDrawer()
    },
    handleExpandSpaceDetails() {
      this.$refs['spacedetails-drawer']?.toggleDrawer()
      this.spaceDetailsOpen = !this.spaceDetailsOpen
      if (window.innerWidth < 768 && !this.$store.state.tutorial.APARTMENT_INFO_MOBILE) {
        this.$store.dispatch(
          tutorialConstants.namespaceAction.TRIGGER_PANEL,
          'APARTMENT_INFO_MOBILE'
        )
      }
      const manager = this.engine3d.getClientManager()
      const intVal = setInterval(() => {
        manager?.resize(false, false)
      }, 10)
      setTimeout(() => {
        manager?.resize()
        clearInterval(intVal)
      }, 501)
    },
    sidebarHideMobile() {
      if (window.innerWidth < 768) {
        this.handleCloseSpaceDetails()
      }
    },
    handleCloseSpaceDetails() {
      this.$refs['spacedetails-drawer']?.closeDrawer()
      this.spaceDetailsOpen = false
    },
    clearFilters() {
      this.$store.dispatch(
        availabilityConstants.withNamespace(availabilityConstants.action.CLEAR_FILTERS)
      )
      this.$store.dispatch(
        projectConstants.withNamespace(projectConstants.action.RESET_SURFACE_FIELDS)
      )
      return this.$store.dispatch('building/viewSpace', {})
    }
  }
}
